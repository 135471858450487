import React from 'react';
import { Link } from 'react-router-dom';

function Cabecalho() {
    return (
        <nav className="w-full z-50 h-16 bg-[#0A2647] fixed top-0 left-0 text-white flex flex-col justify-center px-4 ">
            <div className="flex gap-8">
                <h3>Nossa história</h3>
                <ul className="flex gap-8">
                    <li><Link  to="/timeline">Linha do tempo</Link></li>
                    <li><Link  to="/moments">Momentos</Link></li>
                   <li><Link  to="/letter">Carta</Link></li>
                </ul>
            </div>
        </nav>

    );
}

export default Cabecalho;