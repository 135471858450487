import React from 'react';
import img from './images/love-1.jpeg'
import img2 from './images/love-2.jpeg'
import img3 from './images/love-3.jpeg'
import first from './images/first.jpeg'
import villa from './images/villa.jpeg'
import outback from './images/outback.jpeg'

import campos from './images/campos.jpeg'


const events = [
  {
    title: 'Minha fada ',
    
    image:img2
  },
  {
    title: 'Dorminhoca',
    image: villa
  }, {
    title: 'Comidinhas',
    image:outback
  }
  , {
    title: 'rs ',
    image:first
  }, {
    title: 'Parceria',
    image:img
  }, 
    
  
  

];

const MomentsItem = ({ date, title, description, image }) => (
    <div className="flex flex-col p-6 bg-white rounded-lg hover:shadow-2xl shadow-lg h-full text-center  w-full">
      <h3 className="text-lg font-medium text-gray-900">{title}</h3>
     
      <img  width='200px' src={image}/>
      {/* <alt className="text-gray-700">{description}</alt> */}

      
    </div>
  );
  
  const Moments = () => (
    <ul className="flex p-10 gap-4 h-screen items-center">
      {events.map((item, index) => (
        <li key={index} className="flex flex-col items-center ">
          {index  === 5 ? (
            <>
                
             <MomentsItem {...item} />
          </>
          ) : (
           
             <>
              <MomentsItem {...item} /></>
         
          )}
        </li>
      ))}
    </ul>
  );

  export default Moments;