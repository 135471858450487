import React from 'react';
import Timeline from './Timeline';

const Div1 = () => (
  <div className=" bg-[url('./images/love-1.jpeg')] bg-cover bg-center bg-no-repeat bg-50% bg-30% md:bg-center h-screen w-full flex flex-col sm:items-center">
    <div className="backdrop-blur-sm bg-gray-900 bg-opacity-50 h-full w-full flex flex-col items-center overflow-auto">
      <div className='absolute top-16 h-full'>
         <h1 className=' text-5xl font-mono text-white'>Nossa Linha do tempo</h1>
        
        </div>
      <Timeline />

   
    </div>
    <div className='text-white w-1/3 text-2xl flex flex-col font-bold  sm:justify-center absolute md:right-4 top-16 '>

      {/* <p>"E, acima de tudo, tenham amor,pois o amor une perfeitamente todas as coisas.
        <br />  O amor é o vinculo da perfeição"</p>
      <p>Colossenses 3:14</p> */}


    </div>
  </div>
);

export default Div1;