import React from 'react';
const events = [
  
  {
    title: '1° mensagem ',
    description: 'Implicações',
    date: '16/04/2020'
  },
  {
    title: '1° vista ',
    description: 'Musical da sede',
    date: '17/10/2020'
  },


  {
    title: '1° beijo',
    description: 'Cinema - Shopping Tamboré',
    date: '23/10/2020'
  },

  {
    title: '1° japa ',
    description: 'Harumi',
    date: '12/01/2021'
  },
  // {
  //   title: '1° Outback ',
  //   description: 'Alphaville',
  //   date: '28/12/2021'
  // },
  {
    title: 'First Time +18 ',
    description: 'rsrsrsrsrsrs',
    date: '15/04/2022'
  },
  {
    title: '1° hospital juntos ',
    description: 'Sino - Apendicite',
    date: '08/05/2022'
  },
  {
    title: '1° "Eu Te Amo"',
    description: 'Paris 06',
    date: '13/05/2022'
  },

  {
    title: '1° rolê conhecendo a familia ',
    description: 'Aniversario do Henry',
    date: '18/06/2022'
  },
  {
    title: "1° Wet'n Wild",
    description: '1° "rolê" pra mais longe',
    date: '08/07/2022'
  },
  // {
  //   title: "1° Trilha",
  //   description: 'Parque da Pedra Grande',
  //   date: '11/07/2022'
  // },
  {
    title: '1° casamento juntos ',
    description: 'Casamento do Meneck',
    date: '17/07/2022'
  },
  {
    title: 'PEDIDO DE NAMORO',
    description: 'FINALMENTE <3 ',
    date: '26/08/2022'
  },
  {
    title: '1° Viagem juntos',
    description: 'Campos do Jordão',
    date: '27/08/2022'
  },
  {
    title: '1° Acampamento juntos',
    description: 'São Roque',
    date: '04/11/2022'
  },
  {
    title: '2° Hospital juntos',
    description: 'Infeccção e internação',
    date: '07/01/2023'
  },
];

const TimelineItem = ({ date, title, description }) => (
    <div className="flex flex-col p-6 bg-pink-300 rounded-lg hover:shadow-2xl shadow-lg w-full">
      <h4 className="text-lg font-medium text-gray-900">{title}</h4>
      <h3 className="text-xl font-bold mt-2 text-gray-800">{date}</h3>
      <p className="mt-2 text-gray-700">{description}</p>

      
    </div>
  );
  
  const Timeline = () => (
    
    <ul className="flex flex-col items-center mt-20 p-10">
      {events.map((item, index) => (
        <li key={index} className="flex flex-col items-center ">
          {index % 2 === 0 ? (
            <>
            <p className='text-white'>|</p>
             <TimelineItem {...item} />
            <p className='text-white'>|</p></>
          ) : (
            <div className="flex flex-col items-center">
             <><p className='text-white'>|</p>
              <TimelineItem {...item} /><p className='text-white'>|</p></>
            </div>
          )}
        </li>
      ))}
    </ul>
  );

  export default Timeline;