import React from 'react';




export function getCurrentDate(separator=''){
    var meses = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro"
      ];
    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth();
    let year = newDate.getFullYear();
    let mes = meses[month];
    return `${date}${separator}${mes}${separator}${year}`
    }
    
  const Moments = () => (
    // <div className="w-4/6 h-full bg-[url('./images/letter.jpeg')] bg-cover bg-center bg-no-repeat " >
    <div className='flex flex-col justify-center h-full w-full items-center'>
        <div className='bg-[#FFDEAD] mt-20  w-4/6 max-h-max bg-center font-[cursive] flex flex-col text-2xl'>
            <div className='mx-8 mt-4 max-h-max' > 
            <h3>Osasco, {getCurrentDate(' de ')}</h3>
            <h2 className='font-bold text-2xl'>Carta aberta à minha amada</h2>
                <p>
                  Oi amor, tudo bem?
                </p>
                <p>
                  Venho aqui através dessa carta, expressar meu amor por você e como você mudou minha vida para melhor.
                  Diferente de você eu não sou tão bom com palavras, mas acredito que fazendo de coração, tudo fica bom.
                  </p>
                  <p>
                  Não é segredo para ninguém que desde que você entrou na minha vida, eu sou perdidamente apaixonado e fiquei por tempos tentando esconder que minha atração por você é gigante kkkk.
                  Mas agora não é mais necessário e digo que te amo demais demais demais. Hoje comemoramos 6 meses e é estranho como passou tudo tão rapido e ao mesmo tempo tão devagar para o nosso casamento.
                </p>
                <p>
                  Desejo para nós que os próximos 6 meses, 6 anos, etc., sejam de muitas alegrias, acho muito dificil não acontecer, somos um casal que tem Deus no coração e que se ama demais,
                  eu oro por você e você ora por mim e isso faz a diferença. Foram 6 meses pedindo pra Deus nos abençoar e agradecendo sempre por ter colocado uma mulher maravilhosa como você na minha vida.
                </p>
                <p>
                  Acredito muito em propósitos e que Deus nos unio por algum motivo muito maior e que iremos cumprir com nossas vidas, nos ajudando, nos fortalecendo, orando juntos e passando por cada prova feliz por ter ele conosco.
                </p>
                <p>
                  Para finalizar, gostaria de agradecer pelo seu amor, pela sua paciencia e por tudo que você faz por mim.
                  Tudo é único e me encanta muito. Eu te amo demais Dona Melyssa, que nossas vidas estejam juntas até a nossa morte.
                </p>
                <p>Com amor, seu grande namorado Edu</p>
            </div>
          
        </div>
    </div>
  );

    export default Moments;