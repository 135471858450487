import React from 'react';
import Letter from './Letter';


const Div3 = () => (
  <div className=" bg-[url('./images/love-3.jpeg')] bg-cover bg-center bg-no-repeat bg-50% bg-30% md:bg-center h-screen w-full flex flex-col sm:items-center">
    <div className="backdrop-blur-sm  bg-gray-900 bg-opacity-50 h-full w-full overflow-auto">

         <Letter/>



    </div>
  
  </div>
);

export default Div3;