import React from 'react';
import Moments from './Moments';


const Div2 = () => (
  <div className=" bg-[url('./images/love-2.jpeg')] bg-cover bg-center bg-no-repeat bg-50% bg-30% md:bg-center h-screen w-full flex flex-col sm:items-center">
    <div className="backdrop-blur-sm  bg-gray-900 bg-opacity-50 h-full w-full overflow-auto">

         <Moments/>



    </div>
  
  </div>
);

export default Div2;