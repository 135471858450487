import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Div1 from "./Div1";
import Cabecalho from "./cabecalho";
import Div2 from "./Div2";
import Div3 from "./Div3";
const AppRoutes = () => {
    return (
        <BrowserRouter>
            <Cabecalho />
            <Routes>
                <Route element={<Div1 />} path="/timeline" />
                <Route element={<Div2 />} path="/moments" />
                <Route element={<Div3 />} path="/letter" />
                {/* <Route component = { Sobre }  path="/sobre" />
                <Route component = { Usuario }  path="/usuario" /> */}
            </Routes>
        </BrowserRouter>
    )
}

export default AppRoutes;