// import logo from './logo.svg';
import React from 'react';
import './App.css';
import AppRoutes from './Routes';




export const App = () => {

  return (
    <AppRoutes />
  );
}
